<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <p>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText type="text" v-model="meigaraKey" placeholder="銘柄コード or 銘柄名" v-on:keydown.enter="onSearchClick()" />
          </span>
        </p>
      </div>
      MeigarasearchBaseController {{perf}}<br>
      検索キーワード：{{currentMeigaraKey}}  
      <DataTable :value="meigaras" class="p-datatable-sm" showGridlines stripedRows selectionMode="single">
        <Column field="id" header="No" :sortable="true"></Column>
        <Column field="meigara_cd" header="銘柄コード" :sortable="true"></Column>
        <Column field="meigara_name" header="銘柄名" :sortable="true"></Column>
        <Column field="market_name" header="市場" :sortable="true"></Column>
        <Column field="industry33_cd" header="33業種コード" :sortable="true"></Column>
        <Column field="industry33_name" header="33業種区分" :sortable="true"></Column>
        <Column field="kibo_cd" header="規模コード" :sortable="true"></Column>
        <Column field="kibo_name" header="規模区分" :sortable="true"></Column>  
        <Column field="meigara_name_en" header="銘柄名en" :sortable="true"></Column>
        <Column field="meigara_url" header="銘柄URL" :sortable="true"></Column>    
        <Column field="meigara_overview" header="銘柄概要" :sortable="true"></Column>   
        <Column field="meigara_theme_list" header="銘柄テーマ" :sortable="true"></Column> 
      </DataTable>
    </div>
  </div>
</template>

<script>
import MeigarasearchBaseService from '@/service/MeigarasearchBaseService';

export default {
  data() {
    return {
      perf: null,
      meigaraKey: null,
      currentMeigaraKey: null,
      meigaras: null,
    }
  },
  meigarsearchService: null,
  created() {
    this.meigarasearchBaseService = new MeigarasearchBaseService();
  },
  mounted() {
    this.meigarasearchBaseService.getAll('7203')
          .then(d => {
            this.meigaras = d.data;
            this.perf = d.perf;
          }
        );
        this.currentMeigaraKey = '7203';
        this.meigaraKey = null;
  },
  methods: {
    onSearchClick() {
      if(this.meigaraKey && this.meigaraKey.length > 1) {    
        this.meigarasearchBaseService.getAll(this.meigaraKey)
          .then(d => {
            this.meigaras = d.data;
            this.perf = d.perf;
          }
        );
        this.currentMeigaraKey = this.meigaraKey;
        this.meigaraKey = null;
      }      
    }
  }
}
</script>